import { get_all as get_booking_settings } from 'data/booking_setting';
import { post as post_cart } from 'data/cart';
import { get_calculated as get_calculated_prices } from 'data/tour-price';
import { get_for_booking as _get_trips } from 'data/tour-vessel';

import { tz_display_to_local } from 'utils/date';
import { 
    get as get_cache, 
    cache, 
    remove as remove_cache, 
    remove_key_with_prefix
} from 'utils/cache';
import { convertISOtoDate } from 'utils/date';

import { STEPS, show_fullscreen_modal, load_prev_step } from 'booking-widget/v2/bw';
import { get_tours } from 'booking-widget/v2/common/data';
import { 
    format_calculated_prices,
    set_placeholders,
    setup_selected_tour_day,
    show_loading,
    hide_loading,
    format_currency,
    split_full_name,
} from 'booking-widget/v2/common/util';
import { INFO_TEMPLATE, CHARTERS_REQUESTS, GC_LINK_FORM } from 'booking-widget/v2/templates/info';
import { setup_form } from 'booking-widget/v2/widgets/info-form';
import { setup_gift_promo } from 'booking-widget/v2/widgets/info-gift-promo';
import { setup as setup_nav } from 'booking-widget/v2/widgets/nav';

export const load = (options, bw_events) => {
    let opts = $.extend(true, {
        tour_id: false,
        trip_id: false,
        adult: 2,
        child: 0,
        toddler: 0,
        is_charter: false,
        vessel_type: false
    }, (options || {}));

    let widget_el = show_fullscreen_modal(opts.context_el);
    let adult = parseInt(opts.adult) || 0;
    let child = parseInt(opts.child) || 0;
    let toddler = parseInt(opts.toddler) || 0;

    sanitize_opts(opts);

    if (!options.is_first_load) {
        show_loading(widget_el, true);
    }
    options.is_first_load = false;

    if (!has_required_params(opts)) {
        load_prev_step(opts)
        return;
    }
    _load(opts);

    function _load() {
        let tours_params = {
            id: opts.tour_id, 
            cache_name_prefix: 'tour-', 
            fields_append: 'has_meal'
        }
    
        $.when(get_trip(opts.trip_id)).then(get_alternate_trip).then(function(new_tv_data) {
            // if new_tv_data is null, continue as is.  otherwise reload with the prioritized trip
            if (new_tv_data != null && new_tv_data.id != opts.trip_id) {
                bw_events.on_change({trip_id: new_tv_data.id});
                return;
            }
            let tv_data = get_cache('tour-vessel-detail-' + opts.trip_id);
            if (tv_data == null || $.isEmptyObject(tv_data)) {
                // trip not found, go back a step
                load_prev_step(opts)
                return;
            }
            let prices_params = { 
                data: { 
                    trip_id: opts.trip_id, 
                    adult_count: opts.is_charter == true ? tv_data.vessel[0].capacity_max : adult,
                    child_count: opts.is_charter == true ? 0 : child + toddler
                }
            };
            $.when(
                get_tours(tours_params), 
                get_calculated_prices(prices_params), 
                get_booking_settings({data: {tour_id: opts.tour_id} })
            ).then(function(tours_json, prices_json, booking_now_cached) {
                hide_loading(widget_el, true);
                let tour = tours_json.data ? tours_json.data : {};
                if (tour && tour.length == 1) {
                    tour = tour[0];
                }
                if (!tour || tour.id != tv_data.tour_id) {
                    load_prev_step(opts);
                    return;
                }
                const prices = format_calculated_prices(prices_json);
                const info_el = $(set_placeholders(INFO_TEMPLATE, {
                    selected_tour_params: {
                        message: 'Your Adventure',
                        tv_data: tv_data,
                        tour: tour,
                        prices: prices,
                        adult_count: adult,
                        child_count: child + toddler,
                        is_charter: opts.is_charter,
                        gc_form: GC_LINK_FORM,
                    },
                    max_group_size: tv_data.vessel[0].vessel_type == 'raft' ? '8+' : '10+',
                    submit_bar_total: format_currency(prices.total_price),
                    submit_bar_link_text: 'Checkout'
                }));
                widget_el.append(info_el);


                $('.bw')
                    .off('bw-window-resized', updatePricingSubmitButton)
                    .on('bw-window-resized', updatePricingSubmitButton)
                ;
                updatePricingSubmitButton();
                
                $('.bw-selected-tour__gc-link a', info_el).on('click', function(e) {
                    e.preventDefault();

                    $(this).toggleClass('showing');
                    $('.bw-selected-tour__gc-form', info_el).toggle();
                })

                if (tv_data.vessel && tv_data.vessel.length > 0) {
                    if (tv_data.vessel[0].vessel_type == 'raft' ) {
                        info_el.addClass('bw-raft-tour');
                    }
                    else {
                        info_el.addClass('bw-catamaran-tour');
                    }
                }
    
                if (opts.is_charter) {
                    $('.bw-info', widget_el).addClass('bw-charters');
                    $('.bw-charters-requests', widget_el).append($(CHARTERS_REQUESTS));
                }
                else if ((child + toddler) > 0) {
                    $('.bw-selected-tour__price-list__line--child', widget_el).show();
                }
    
                let cart_el = $('.bw-selected-tour', widget_el);
                let gift_promo_el = $('.bw-gift-promo', widget_el);
                let form_el = $('.bw-form--info', widget_el);
                let error_el = $('.bw-form-errors', widget_el);
    
                setup_nav(widget_el, STEPS.info.name);
                setup_gift_promo({
                    widget_el: widget_el,
                    cart_el: cart_el,
                    gift_promo_el: gift_promo_el,
                }, {
                    trip_id: opts.trip_id,
                    pax_count: {
                        adult: adult,
                        child: child + toddler
                    },
                    gift_certificate_id: opts.gift_certificate_id,
                    promo_code: opts.promo_code
                });
                setup_selected_tour_day(info_el, tv_data.start_time, false);
                setup_form({
                        form_el: form_el,
                        error_el: error_el,
                    }, 
                    $.extend(true, {}, opts, {
                        tv_data: tv_data,
                        tour: tour,
                        on_submit: function(data) {
                            // get the discount
                            let promo_code = $('span[data-target="promo-code"]', gift_promo_el).text();
                            let gc_id = $('input[name="gift_certificate_id"]', gift_promo_el).val();
    
                            if (promo_code != '') {
                                data.promo_code = promo_code;
                                prices.promo_code = promo_code;
                            }
                            if (gc_id != '') {
                                data.gift_certificate_id = gc_id;
                            }
    
                            // set expiration time for the saved data
                            let start_dt = tz_display_to_local(convertISOtoDate(tv_data.start_time), opts.utc_offset);
                            start_dt.setHours(start_dt.getHours() + 24);
                            data.expiration_time = start_dt.getTime();
    
                            const {first_name, last_name} = split_full_name(data.full_name[0]);

                            // save cart
                            post_cart({
                                data: {
                                    first_name: first_name,
                                    last_name: last_name,
                                    email: data.email,
                                    phone: data.phone,
                                    tour_vessel_id: data.trip_id,
                                    adult_count: data.adult,
                                    child_count: data.child,
                                    toddler_count: data.toddler,
                                    is_charter: data.is_charter
                                }
                            })
    
                            $('.info-error-message').hide();
                            bw_events.on_submit($.extend({}, data, {prices: prices}));
                        }
                    })
                );

                $('.bw-mobile-submit-bar__button').on('click', function(e) {
                    e.preventDefault();
                    form_el.trigger('submit');
                });

                bw_events.on_loaded({
                    tour: tour,
                    adult: opts.adult,
                    child: opts.child,
                    toddler: opts.toddler,
                    prices: prices,
                });
            });
        });
    }

    function updatePricingSubmitButton() {
        const update_form_el = $('.bw-form--update-pricing', opts.context_el);
        const submit_el = $('input[type="submit"]', update_form_el);
        const gc_el = $('input[name="gift_promo_code"]', update_form_el);
        
        if (submit_el.length > 0) {
            if ($(window).width() < 768) {
                submit_el.val('Update');
            }
            else {
                submit_el.val('Update Pricing');
            }
        }

        if (gc_el.length > 0) {
            if ($(window).width() < 768) {
                gc_el.attr('placeholder', 'Gift Certificate / Promo Code');
            }
            else {
                gc_el.attr('placeholder', 'Gift Certificate Number / Promo Code');
            }
            
        }
    }

    // this only returns the data, not the full json response
    // this is so tours can cache data it already got
    function get_trip(trip_id) {
        let d = $.Deferred();
    
        let cached = get_cache('tour-vessel-detail-' + trip_id);
        if (cached != false) {
            return d.resolve(cached);
        }

        _get_trips({
            id: trip_id,
            on_done: function(json) {
                if (json.success) {
                    cache('tour-vessel-detail-' + trip_id, json.data);
                    d.resolve(json.data);
                }
                else {
                    // failed to get a trip, usually means it's no longer available
                    // clear booking-tours
                    remove_cache('tour-vessel-detail-' + trip_id);
                    remove_key_with_prefix('booking-tours-');
                    d.resolve(null);
                }
            }
        });
    
        return d.promise();
    }

    // if the current trip has a booking priority > 0, check if there's higher priority trips
    function get_alternate_trip(tv_data) {
        let d = $.Deferred();
        if (!opts.is_charter && tv_data && tv_data.vessel[0].booking_priority == 0) {
            d.resolve(null);
        }

        if (tv_data) {
            let params = {
                tour_id: tv_data.tour_id,
                start_timestamp_between: [tv_data.start_timestamp*1000, tv_data.start_timestamp*1000],
                pax_count: opts.adult + opts.child + opts.toddler,
                order: 'vessel.booking_priority,id',
                on_done: function(json) {
                    if (json.data && json.data.length > 0) {
                        d.resolve(json.data[0]);
                    }
                    else {
                        d.resolve(null);
                    }
                }
            }
            if (opts.is_charter) {
                // charter is reverse priority
                params.booking_priority_gt = tv_data.vessel[0].booking_priority;
                params.pax_count = tv_data.vessel[0].capacity_max;
            }
            else {
                params.booking_priority_lt = tv_data.vessel[0].booking_priority;
            }
            _get_trips(params);
        }
        else {
            d.resolve(null);
        }

        return d.promise();
    }

    function sanitize_opts(opts) {
        let adult = parseInt(opts.adult) || 0;
        let child = parseInt(opts.child) || 0;
        let toddler = parseInt(opts.toddler) || 0;
        const total_count = adult + child + toddler;
        const child_count = child + toddler;

        // re-set if total count doesn't match up
        if ((opts.full_name && total_count != opts.full_name.length)
            || (opts.is_child && total_count != opts.is_child.length)
            || (opts.is_child && child_count != opts.is_child.filter(str => str === 'on').length)) {
                opts.full_name = new Array(total_count).fill('');
                const is_child = [];
                for (let i = 0; i < adult; i++) {
                    is_child.push('off');
                }
                for (let i = 0; i < child; i++) {
                    is_child.push('on');
                }
                for (let i = 0; i < toddler; i++) {
                    is_child.push('on');
                }
                opts.is_child = is_child;
        }
    }
}

export const to_url_params = (search_params) => {
    let has_all_keys = [
        'tour_id',
        'trip_id',
        'adult',
        'child',
        'toddler'
    ].every((i) => search_params.hasOwnProperty(i));
    
    if (!has_all_keys) {
        return false;
    }

    let url_params = {
        step: STEPS.info.name,
        tour_id: search_params.tour_id,
        trip_id: search_params.trip_id,
        adult: search_params.adult,
        child: search_params.child,
        toddler: search_params.toddler,
    }
    if (search_params.vessel_type) {
        url_params.vessel_type = search_params.vessel_type;
    }
    if (search_params.is_charter) {
        url_params.is_charter = search_params.is_charter
    }
    if (search_params.amount_only) {
        url_params.amount_only = search_params.amount_only;
    }
    if (search_params.amount_only_taxes) {
        url_params.amount_only_taxes = search_params.amount_only_taxes;
    }
    if (search_params.amount_only_fees) {
        url_params.amount_only_fees = search_params.amount_only_fees;
    }

    return url_params;
}

export const has_required_params = (params) => {
    return !!(params.trip_id && params.trip_id != 0
           && params.tour_id && params.tour_id != 0 
           && params.adult && params.adult != 0
    );
}